import commerceConfig from '@commerce/config'
import { PrismicImage } from '@lib/prismic'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
interface props {
  primary: {
    desktop_image: PrismicImage
    mobile_image?: PrismicImage
    full_width: boolean
    link?: string
  }
  applyWrapper?: boolean
}
const Banner = ({ primary, applyWrapper = true }: props) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const desktopImage = primary?.desktop_image
  const mobileImage = primary?.mobile_image && primary?.mobile_image?.url ? primary?.mobile_image : desktopImage
  if (!desktopImage) return <></>
  const fullWidth = primary?.full_width
  const link = primary?.link

  const children = (
    <>
      <div className="tablet:block hidden w-full relative">
        <Image
          src={`${decodeURI(desktopImage?.url)}`}
          alt={desktopImage?.alt || defaultAlt}
          className="object-cover"
          width={desktopImage?.dimensions?.width}
          height={desktopImage?.dimensions?.height}
          style={{ width: '100%' }}
          placeholder="blur"
          blurDataURL={`${decodeURI(desktopImage?.url)}&blur=300`}
        />
      </div>
      <div className="block w-full tablet:hidden ">
        <Image
          src={`${decodeURI(mobileImage?.url)}`}
          alt={mobileImage?.alt || defaultAlt}
          width={mobileImage?.dimensions?.width}
          height={mobileImage?.dimensions?.height}
          className="object-cover"
          style={{ width: '100%' }}
          placeholder="blur"
          blurDataURL={`${decodeURI(mobileImage?.url)}&blur=300`}
        />
      </div>
    </>
  )

  return (
    <section className={cn({ 'tablet:wrapper': !fullWidth && applyWrapper })}>
      {link ? (
        <Link href={link} passHref>
          {children}
        </Link>
      ) : (
        children
      )}
    </section>
  )
}

export default Banner
